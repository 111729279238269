import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Seo from '~components/Seo'
import Section from '~components/Section'
import DataViewer from '~utils/DataViewer'
import { mobile, ArrowButton, ChevRight } from '~styles/global'
import Image from '~components/Image'
import Button from '~components/Button'

const LoansIndex = ({ data }) => {
	const { page } = data
	return(
		<>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<Header>
				<BgImage image={page?.backgroundImage} cover hideMobile={page?.backgroundImageMobile}/>
				<BgImageMobile image={page?.backgroundImageMobile} cover/>
				<HeaderContent>
					<HeaderContentInner>
						<Heading>{page.heading}</Heading>
						<Buttons>
							{page?.buttons?.map(button => (
								<StyledArrowButton key={button.text} link={button?.link} large border>
									{button.text}<ChevRight />
								</StyledArrowButton>
							))}
						</Buttons>
					</HeaderContentInner>
				</HeaderContent>
			</Header>
			<DataViewer data={page} name="page"/>
		</>
	)
}

const Header = styled.div`
	position: relative;
`
const BgImage = styled(Image)`
	height: calc(100vh - 116px);
	${mobile}{
		display: ${ props => props.hideMobile ? 'none' : 'block'};
	}
`
const BgImageMobile = styled(Image)`
	display: none;
	${mobile}{
		display: block;
		height: calc(100vh - 156px);
	}
`
const HeaderContent = styled(Section)`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	>div{
		height: 100%;
	}
`
const HeaderContentInner = styled.div`
	align-self: center;
	grid-column: 1/13;
	max-width: 430px;
	margin: 60px auto 0;
	${mobile}{
		margin: 0 auto;
		width: 100%;
	}
`
const Heading = styled.h1`
	margin-bottom: 48px;
	color: var(--white);
	font-weight: 300;
	${mobile}{
		text-align: center;
		margin-bottom: 28px;
	}
`
const Buttons = styled.div`
	max-width: 420px;
`
const StyledArrowButton = styled(ArrowButton)`
	margin-bottom: 14px;
	padding: 10px 20px 10px 30px;
	max-width: 410px;
	transition: background-color 0.3s, color 0.25s;
	font-weight: 300;
	:hover{
		background-color: var(--white);
		color: var(--black);
	}
`

LoansIndex.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query LoansIndexQuery($slug: String) {
    page: sanityLoansIndex(slug: {current: {eq: $slug}}) {
      title
			heading
			buttons {
				text
				link {
					...link
				}
			}
			backgroundImage {
				...imageWithAlt
			}
			backgroundImageMobile {
				...imageWithAlt
			}
			seo{
				...seo
			}
    }
  }
`

export default LoansIndex